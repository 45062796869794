import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { createCookie, removeCookie } from 'Services/cookie/core__cookies';

const InteliticsTrackingReact = ({ appConfig }) => {
  const {
    trackingparam: trackingParam,
    customtrackingexpresion: customTrackingExpresion,
  } = appConfig;

  useEffect(() => {
    // On load of the page
    const urlParams = new URLSearchParams(window.location.search);

    const cookieName = 'inteliticsTag';
    const urlTrackingParam = urlParams.get(trackingParam || 'btag');

    // Using regular expression to capture the GUID
    const re = customTrackingExpresion && new RegExp(customTrackingExpresion);

    // Check if a match is found
    const [, cookie] = urlTrackingParam?.match(re || /cid_(.*?)\.pid/) || [];

    if (cookie) {
      createCookie(cookieName, cookie);
    } else {
      removeCookie(cookieName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return '';
};

InteliticsTrackingReact.propTypes = {
  appConfig: PropTypes.object,
};
export default InteliticsTrackingReact;
